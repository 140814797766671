/************************************
 *   IMPORTS
 ************************************/


// Import settings
@import "variables";


// Bootstrap
$icon-font-path: '../node_modules/bootstrap-sass/assets/fonts/bootstrap/';
@import "../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap";


// C3
@import "../node_modules/c3/src/scss/main";


// Toastr
@import "../node_modules/toastr/toastr";
