// From bootstrap
$font-size-base: 14px;
$brand-primary: #337ab7;
$navbar-height: 50px;
$navbar-inverse-bg: $brand-primary;
$navbar-inverse-link-color: #fff;
$panel-default-border: #ddd;
$gray-light: #777;
$state-danger-text: #a94442;


// Fonts
$text-font: 'Open Sans', sans-serif;


// Animation
$default-transition-duration: .3s;
